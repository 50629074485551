// modifer: styles/jss/nextjs-material-kit-pro/pages/ecommerceSections/latestOffersStyle
import { cardTitle, coloredShadow, grayColor, mlAuto } from 'styles/jss/original/base';

import tooltipsStyle from 'styles/jss/nextjs-material-kit-pro/tooltipsStyle.js';

const styles = {
  cardTitle,
  ...tooltipsStyle,
  coloredShadow,
  card: {
    '@media (max-width: 576px)': {
      marginTop: '20px'
    }
  },
  cardBody: {
    textAlign: 'center',
    padding: '0.9375rem 1.25rem 0',
    '@media (max-width: 640px)': {
      padding: '0.9375rem 0.625rem 0'
    }
  },
  cardCategory: {
    '@media (max-width: 640px)': {
      marginTop: '5px',
      marginBottom: '0'
    }
  },
  flex: {
    display: 'flex'
  },
  mlAuto,
  priceContainer: {
    display: 'inline-flex'
  },
  price: {
    fontSize: '1.125rem',
    color: grayColor[22],
    '@media (max-width: 640px)': {
      fontSize: '1rem'
    }
  },
  stats: {
    color: grayColor[0],
    display: 'flex',

    '& svg': {
      width: '24px',
      height: '24px'
    }
  }
};

export default styles;
