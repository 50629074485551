import React, { useCallback, useState, useEffect } from 'react';
import Link from 'next/link';
import Card from '@/components/material-ui-kit/components/Card/Card';
import CardHeader from '@/components/material-ui-kit/components/Card/CardHeader';
import CardBody from '@/components/material-ui-kit/components/Card/CardBody';
import CardFooter from '@/components/material-ui-kit/components/Card/CardFooter';
import Button from '@/components/material-ui-kit/components/CustomButtons/Button';
import Muted from '@/components/material-ui-kit/components/Typography/Muted';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Favorite from '@material-ui/icons/Favorite';
import styles from 'styles/jss/original/components/shoppings/shoppingCarouselListItem';
import classNames from 'classnames';
import CurrencyFormat from 'react-currency-format';
import LazyLoadImage from '@/components/common/ui/LazyloadImage';
import { IShopping } from '@/@types/generated/contentful';

const IMAGE_HEIGHT_PC = 250;
const IMAGE_HEIGHT_SP = 150;

// @ts-expect-error
const useStyles = makeStyles(styles);

type ComponentProps = {
  shopping: IShopping;
  onRequestUpdateFavorite: (id: string) => void;
  isFavorite: boolean;
  isSp: boolean;
  invalid: boolean;
};

const FavoriteIdeaListItem: React.FC<ComponentProps> = ({
  shopping,
  onRequestUpdateFavorite,
  isFavorite,
  isSp,
  invalid
}) => {
  const classes = useStyles();
  const [imageHeight, setImageHeight] = useState(IMAGE_HEIGHT_PC);
  useEffect(() => {
    if (isSp) setImageHeight(IMAGE_HEIGHT_SP);
  }, [isSp]);
  const handleClick = useCallback(
    (id) => {
      onRequestUpdateFavorite(id);
    },
    [onRequestUpdateFavorite]
  );

  return (
    <Card product plain className={classes.card}>
      <CardHeader image plain>
        <Link href={`/shoppings/${shopping.fields.slug}`}>
          <a>
            <LazyLoadImage height={imageHeight}>
              {(onLoad) => (
                <picture>
                  <source
                    srcSet={`${shopping.fields.thumbnail.fields.file.url}?fm=webp`}
                    media='(min-width: 641px)'
                    onLoad={onLoad}
                  />
                  <source
                    srcSet={`${shopping.fields.thumbnail.fields.file.url}?fm=webp&fit=pad&w=480`}
                    media='(max-width: 640px)'
                    onLoad={onLoad}
                  />
                  <img
                    src={`${shopping.fields.thumbnail.fields.file.url}?fit=pad&w=720`}
                    alt={shopping.fields.title}
                    onLoad={onLoad}
                  />
                </picture>
              )}
            </LazyLoadImage>
            <div className={classes.coloredShadow} />
          </a>
        </Link>
      </CardHeader>
      <CardBody className={classes.cardBody} plain>
        <Link href={`/shoppings/${shopping.fields.slug}`}>
          <a>
            <h4 className={classes.cardTitle}>{shopping.fields.title}</h4>
            <Muted>
              <h6 className={classes.cardCategory}>{shopping.fields.cat}</h6>
            </Muted>
          </a>
        </Link>
      </CardBody>
      <CardFooter plain>
        <div className={classes.priceContainer}>
          <span className={classes.price}>
            <CurrencyFormat
              value={shopping.fields.price}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'¥'}
            />
          </span>
        </div>
        <div className={classNames(classes.stats, classes.mlAuto)}>
          <Tooltip
            id='tooltip-top'
            title='お気に入り'
            placement='top'
            classes={{ tooltip: classes.tooltip }}
          >
            {/* @ts-expect-error */}
            <Button
              justIcon
              simple
              color={isFavorite ? 'warning' : 'white'}
              link={!isFavorite}
              disabled={invalid}
              onClick={() => handleClick(shopping.fields.id)}
            >
              <Favorite />
            </Button>
          </Tooltip>
        </div>
      </CardFooter>
    </Card>
  );
};

export default FavoriteIdeaListItem;
