import React, { useMemo } from 'react';
import MenuCarouselList from '@/components/menus/MenuCarouselList';
import IdeaList from '@/components/ideas/IdeaList';
import ShoppingCarouselList from '@/components/shoppings/ShoppingCarouselList';
import {
  MENU_COOKING_LATEST,
  MENU_NONE_COOKING_LATEST,
  MENU_TAXONOMY_COOKING,
  MENU_TAXONOMY_NONE_COOKING,
  IDEA_LATEST,
  SHOPPING_FEATURE_RECOMMEND
} from '@/constants/app';
import useMenuList from '@/hooks/useMenuList';
import useIdeaList from '@/hooks/useIdeaList';
import useShoppingList from '@/hooks/useShoppingList';
import { IMenu, IIdea, IShopping } from '@/@types/generated/contentful';

type Contents = {
  title:
    | typeof MENU_COOKING_LATEST
    | typeof MENU_NONE_COOKING_LATEST
    | typeof IDEA_LATEST
    | typeof SHOPPING_FEATURE_RECOMMEND;
  list: IMenu[] | IIdea[] | IShopping[];
  type: 'menu' | 'idea' | 'shopping';
  showIndex?: boolean;
}[];

const HomeListContainer: React.FC = () => {
  const menuList = useMenuList();
  const ideaList = useIdeaList();
  const shoppingList = useShoppingList();
  const contents = useMemo<Contents>(
    () => [
      {
        title: MENU_COOKING_LATEST,
        list: menuList.filter((item) => item.fields.type === MENU_TAXONOMY_COOKING),
        type: 'menu'
      },
      {
        title: MENU_NONE_COOKING_LATEST,
        list: menuList.filter((item) => item.fields.type === MENU_TAXONOMY_NONE_COOKING),
        type: 'menu'
      },
      {
        title: IDEA_LATEST,
        list: ideaList,
        type: 'idea',
        showIndex: false
      },
      {
        title: SHOPPING_FEATURE_RECOMMEND,
        list: shoppingList && shoppingList,
        type: 'shopping'
      }
    ],
    [menuList, ideaList, shoppingList]
  );
  return (
    <>
      {contents.map((item) => {
        const contentType = item.type;
        switch (contentType) {
          case 'menu':
            return (
              <MenuCarouselList
                title={item.title}
                menuList={item.list as IMenu[]}
                key={item.title}
              />
            );
          case 'idea':
            return (
              <IdeaList
                title={item.title}
                ideaList={item.list as IIdea[]}
                showIndex={item.showIndex}
                key={item.title}
              />
            );
          case 'shopping':
            return (
              <ShoppingCarouselList
                title={item.title}
                shoppingList={item.list as IShopping[]}
                key={item.title}
              />
            );
          default:
            break;
        }
      })}
    </>
  );
};

export default HomeListContainer;
