// modifer: styles/jss/nextjs-material-kit-pro/pages/profilePageStyle
import { section, container, cardTitle, title, boxShadow } from 'styles/jss/original/base';

import imagesStyle from 'styles/jss/nextjs-material-kit-pro/imagesStyles.js';

const styles = {
  section: {
    ...section,
    padding: '60px 0 40px',
    '@media (max-width: 992px)': {
      padding: '45px 0 30px'
    },
    '@media (max-width: 640px)': {
      padding: '30px 0 20px'
    }
  },
  container,
  imagesStyle,
  title,
  card: {
    textAlign: 'left !important',
    border: '1px solid #e0e0e0',
    ...boxShadow,
    '@media (max-width: 640px)': {
      marginTop: '15px',
      marginBottom: '15px'
    }
  },
  gridItem: {
    padding: '0',
    textAlign: 'center',
    marginLeft: '-15px',
    '@media (max-width: 768px)': {
      marginLeft: '0'
    },
    '@media (max-width: 640px)': {
      marginLeft: '-15px'
    }
  },
  cardBody: {
    padding: '0.9375rem 0.3125rem',
    '@media (max-width: 1024px)': {
      padding: '0.625rem 0.3125rem'
    },
    '@media (max-width: 768px)': {
      padding: '0.9375rem 0.3125rem'
    }
  },
  cardTitle: {
    ...cardTitle,
    '@media (max-width: 1024px)': {
      marginBottom: '0.625rem'
    },
    '@media (max-width: 320px)': {
      marginTop: '10px !important'
    }
  }
};

export default styles;
