import React from 'react';
import Link from 'next/link';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from '@/components/material-ui-kit/components/Grid/GridContainer';
import GridItem from '@/components/material-ui-kit/components/Grid/GridItem';
import Card from '@/components/material-ui-kit/components/Card/Card';
import CardHeader from '@/components/material-ui-kit/components/Card/CardHeader';
import CardBody from '@/components/material-ui-kit/components/Card/CardBody';
import Muted from '@/components/material-ui-kit/components/Typography/Muted';
import styles from 'styles/jss/original/components/homes/homeCategoryList';
import HomeMainCategoryMenuImage from 'public/img/contents/home-main-category-menu.jpg';
import HomeMainCategoryIdeaImage from 'public/img/contents/home-main-category-idea.jpg';
import HomeMainCategoryShoppingImage from 'public/img/contents/home-main-category-shopping.jpg';

// @ts-expect-error
const useStyles = makeStyles(styles);

const categoryList = [
  {
    title: '献立',
    description: '狭いキッチンでも作れる献立',
    link: '/menus',
    img: HomeMainCategoryMenuImage
  },
  {
    title: 'アイデア',
    description: '料理や暮らしのヒント',
    link: '/ideas',
    img: HomeMainCategoryIdeaImage
  },
  {
    title: 'ショッピング',
    description: '様々な便利アイテムの紹介',
    link: '/shoppings',
    img: HomeMainCategoryShoppingImage
  }
];

const HomeCategoryList = () => {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <h3 className={classes.title}>small dish でできること</h3>
        {/* @ts-expect-error プロパティ「justify」は、タイプ「IntrinsicAttributes & Pick<InferProps<{ children.Requireable<ReactNodeLike>; className」に存在しません。*/}
        <GridContainer justify='center'>
          {categoryList.map((item) => {
            return (
              // @ts-expect-error
              <GridItem xs={12} sm={4} md={4} lg={4} key={item.title}>
                <Card profile plain className={classes.card}>
                  <Link href={item.link}>
                    <a>
                      <GridContainer>
                        {/* @ts-expect-error */}
                        <GridItem xs={5} sm={12} md={5} lg={5}>
                          <CardHeader image plain>
                            <img src={item.img} alt={item.title} />
                          </CardHeader>
                        </GridItem>
                        {/* @ts-expect-error */}
                        <GridItem xs={7} sm={12} md={7} lg={7} className={classes.gridItem}>
                          <CardBody plain className={classes.cardBody}>
                            <h4 className={classes.cardTitle}>{item.title}</h4>
                            <Muted>
                              <h6>{item.description}</h6>
                            </Muted>
                          </CardBody>
                        </GridItem>
                      </GridContainer>
                    </a>
                  </Link>
                </Card>
              </GridItem>
            );
          })}
        </GridContainer>
      </div>
    </div>
  );
};

export default HomeCategoryList;
