import React, { useCallback, useEffect, useState } from 'react';
import Link from 'next/link';
import { makeStyles } from '@material-ui/core/styles';
import Favorite from '@material-ui/icons/Favorite';
import Button from '@/components/material-ui-kit/components/CustomButtons/Button';
import Card from '@/components/material-ui-kit/components/Card/Card';
import CardHeader from '@/components/material-ui-kit/components/Card/CardHeader';
import CardBody from '@/components/material-ui-kit/components/Card/CardBody';
import CardFooter from '@/components/material-ui-kit/components/Card/CardFooter';
import Tooltip from '@material-ui/core/Tooltip';
import styles from 'styles/jss/original/components/menus/menuCarouselListItem';
import LazyLoadImage from '@/components/common/ui/LazyloadImage';
import { IMenu } from '@/@types/generated/contentful';

const IMAGE_HEIGHT_PC = 320;
const IMAGE_HEIGHT_SP = 285;

// @ts-expect-error
const useStyles = makeStyles(styles);

type ComponentProps = {
  menu: IMenu;
  onRequestUpdateFavorite: (id: string) => void;
  isFavorite: boolean;
  isSp: boolean;
  invalid: boolean;
};

const MenuCarouselListItem: React.FC<ComponentProps> = ({
  menu,
  onRequestUpdateFavorite,
  isFavorite,
  isSp,
  invalid
}) => {
  const classes = useStyles();
  const [imageHeight, setImageHeight] = useState(IMAGE_HEIGHT_PC);
  useEffect(() => {
    if (isSp) setImageHeight(IMAGE_HEIGHT_SP);
  }, [isSp]);
  const handleClick = useCallback(
    (id: string) => {
      onRequestUpdateFavorite(id);
    },
    [onRequestUpdateFavorite]
  );

  return (
    <Card product className={classes.card}>
      <CardHeader image>
        <Link href={`/menus/${menu.fields.slug}`}>
          <a>
            <LazyLoadImage height={imageHeight}>
              {(onLoad) => (
                <picture>
                  <source
                    srcSet={`${menu.fields.thumbnail.fields.file.url}?fm=webp`}
                    media='(min-width: 641px)'
                    onLoad={onLoad}
                  />
                  <source
                    srcSet={`${menu.fields.thumbnail.fields.file.url}?fm=webp&fit=pad&w=420`}
                    media='(max-width: 640px)'
                    onLoad={onLoad}
                  />
                  <img
                    src={`${menu.fields.thumbnail.fields.file.url}?fit=pad&w=440`}
                    alt={menu.fields.title}
                    onLoad={onLoad}
                  />
                </picture>
              )}
            </LazyLoadImage>
          </a>
        </Link>
      </CardHeader>
      <CardBody className={classes.cardBody}>
        <Link href={`/menus/${menu.fields.slug}`}>
          <a>
            <h6 className={classes.cardCategory}>{menu.fields.cat}</h6>
            <h4 className={classes.cardTitle}>{menu.fields.title}</h4>
          </a>
        </Link>
      </CardBody>
      <CardFooter className={classes.cardFooter}>
        <Tooltip
          id='tooltip-top'
          title='お気に入り'
          placement='top'
          classes={{ tooltip: classes.tooltip }}
        >
          {/* @ts-expect-error */}
          <Button
            justIcon
            simple
            color={isFavorite ? 'warning' : 'white'}
            link={!isFavorite}
            disabled={invalid}
            onClick={() => handleClick(menu.fields.id)}
          >
            <Favorite />
          </Button>
        </Tooltip>
      </CardFooter>
    </Card>
  );
};

export default MenuCarouselListItem;
