import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateUser } from '@/actions/user';
import { useMedia } from 'react-use';
import useUser from '@/hooks/useUser';
import MenuCarouselListItem from '@/components/menus/MenuCarouselListItem';
import { useUiStatus } from '@/hooks/useUiStatus';
import { favoritePost } from '@/domains/firestore/user';
import { IMenu } from '@/@types/generated/contentful';

type ComponentProps = {
  menu: IMenu;
};

const MenuCarouselListItemContainer: React.FC<ComponentProps> = ({ menu }) => {
  const [isLoading, setLoading] = useState(false);
  const { setModalState } = useUiStatus();
  const dispatch = useDispatch();
  const isSp = useMedia('(max-width: 640px)');
  const user = useUser();
  const isFavorite = !!user?.favorited?.includes(menu.fields.id);
  const handleRequestUpdateFavorite = useCallback(
    async (postId: string) => {
      if (!user) {
        setModalState(true);
      } else {
        setLoading(true);
        await favoritePost({ userId: user.uid, postId });
        await dispatch(updateUser(user.uid));
        setLoading(false);
      }
    },
    [dispatch, setModalState, user]
  );
  return (
    <MenuCarouselListItem
      menu={menu}
      onRequestUpdateFavorite={handleRequestUpdateFavorite}
      isFavorite={isFavorite}
      isSp={isSp}
      invalid={isLoading}
    />
  );
};

export default MenuCarouselListItemContainer;
