// modifer: styles/jss/nextjs-material-kit-pro/pages/ecommerceSections/latestOffersStyle
import { section, container, title } from 'styles/jss/original/base';

const styles = {
  section,
  container,
  title,
  flex: {
    display: 'flex'
  }
};

export default styles;
