import React from 'react';
import { NextPage } from 'next';
import { makeStyles } from '@material-ui/core/styles';
import Main from '@/layouts/Main';
import { fetchNewMenus } from '@/actions/menu';
import { fetchNewIdeas } from '@/actions/idea';
import { fetchFeatureShoppings } from '@/actions/shopping';
import PageHeader from '@/components/common/PageHeader';
import BottomBanner from '@/components/common/BottomBanner';
import Head from '@/components/common/Head';
import HomeCategoryList from '@/components/homes/HomeCategoryList';
import HomeListContainer from '@/containers/homes/HomeListContainer';
import styles from 'styles/jss/original/pages';
import { SHOPPING_FEATURE_RECOMMEND } from '@/constants/app';
import meta from '@/config/meta';
import basicAuthentication from '@/lib/basicAuthentication';
import { wrapper, AppDispatch } from '@/store/configureStore';

type ComponentProps = {
  pathname: {
    pathname: string;
  };
};

const IDEA_LATEST_LIST_COUNT = 4;
const MENU_LATEST_LIST_COUNT = 7;

// @ts-expect-error
const useStyles = makeStyles(styles);

const IndexPage: NextPage<ComponentProps> = ({ pathname }) => {
  const classes = useStyles();

  return (
    <Main pathname={pathname.pathname}>
      <Head
        title={meta.index.title}
        description={meta.index.description}
        image={meta.index.image}
        url={meta.index.url}
      />
      <PageHeader type='home' />
      <div className={classes.main}>
        <HomeCategoryList />
        <HomeListContainer />
        <BottomBanner />
      </div>
    </Main>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(async ({ store, req, res }) => {
  if (!process.browser) {
    basicAuthentication({ req, res });
  }
  await Promise.all([
    (store.dispatch as AppDispatch)(fetchNewMenus(MENU_LATEST_LIST_COUNT)),
    (store.dispatch as AppDispatch)(fetchNewIdeas(IDEA_LATEST_LIST_COUNT)),
    (store.dispatch as AppDispatch)(fetchFeatureShoppings(SHOPPING_FEATURE_RECOMMEND))
  ]);
  return { props: { pathname: '/' } };
});

export default IndexPage;
