// modifer: styles/jss/nextjs-material-kit-pro/pages/productStyle
import { cardTitle, cardHeader, grayColor } from 'styles/jss/original/base';

import tooltipsStyle from 'styles/jss/nextjs-material-kit-pro/tooltipsStyle.js';

const styles = {
  ...tooltipsStyle,
  card: {
    marginTop: '60px',
    '@media (max-width: 576px)': {
      marginTop: '50px'
    }
  },
  flex: {
    display: 'flex'
  },
  cardCategory: {
    textAlign: 'center',
    marginTop: '10px',
    color: grayColor[0]
  },
  cardHeader: {
    ...cardHeader,
    margin: '0 15px 0'
  },
  cardTitle: {
    ...cardTitle,
    textAlign: 'center'
  },
  cardBody: {
    padding: '0.9375rem 1.25rem 0',
    '@media (max-width: 640px)': {
      padding: '0.9375rem 0.625rem 0'
    }
  },
  cardFooter: {
    justifyContent: 'flex-end',
    padding: '0.9375rem 1.25rem 0.9375rem 1.875rem',
    '@media (max-width: 640px)': {
      padding: '0.9375rem 0.625rem'
    },

    '& svg': {
      width: '24px',
      height: '24px'
    }
  }
};

export default styles;
