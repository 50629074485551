// modifer: styles/jss/nextjs-material-kit-pro/pages/productStyle
import { section, container, title } from 'styles/jss/original/base';

import tooltipsStyle from 'styles/jss/nextjs-material-kit-pro/tooltipsStyle.js';

const styles = {
  section,
  container,
  title,
  ...tooltipsStyle,
  flex: {
    display: 'flex'
  }
};

export default styles;
