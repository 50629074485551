import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { makeStyles } from '@material-ui/core/styles';
import styles from 'styles/jss/original/components/menus/menuCarouselList';
import MenuCarouselListItemContainer from '@/containers/menus/MenuCarouselListItemContainer';
import _ from 'lodash';
import { IMenu } from '@/@types/generated/contentful';

// @ts-expect-error
const useStyles = makeStyles(styles);
const Swiper = dynamic(() => import('react-id-swiper'));

type ComponentProps = {
  title: string;
  menuList: IMenu[];
};

const MenuCarouselList: React.FC<ComponentProps> = ({ title, menuList = [] }) => {
  const classes = useStyles();
  const swiperParams = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      1280: {
        slidesPerView: 4,
        spaceBetween: 30
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 20
      },
      640: {
        slidesPerView: 'auto',
        spaceBetween: 20
      },
      375: {
        slidesPerView: 'auto',
        spaceBetween: 10
      },
      320: {
        slidesPerView: 'auto',
        spaceBetween: 10
      }
    }
  };
  useEffect(() => {
    const event = new CustomEvent('resize');
    _.defer(() => window.dispatchEvent(event));
  }, [menuList]);

  if (menuList.length === 0) return null;
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <h3 className={classes.title}>{title}</h3>
        <Swiper {...swiperParams}>
          {menuList.map((item, index) => {
            return (
              <div className={classes.flex} key={`menu-item-${index.toString()}`}>
                <MenuCarouselListItemContainer menu={item} />
              </div>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default MenuCarouselList;
