import { useSelector } from 'react-redux';
import { ShoppingState } from '@/reducers/shopping';

export default function useShoppingList() {
  const shoppingList = useSelector(
    (state: { shopping: ShoppingState }) => state.shopping.shoppingList
  );

  return shoppingList;
}
